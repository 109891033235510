import React, { useEffect, useMemo, useState } from "react";
import { clone } from "@formily/shared";
import { createForm } from "@formily/core";
import { createSchemaField } from "@formily/react";
import { GlobalRegistry } from "@designable/core";
import { requestIdle, uid } from "@designable/shared";
import { usePrefix } from "@designable/react";
import {
  Form,
  ArrayTable,
  Input,
  Select,
  FormItem,
  NumberPicker,
  Switch,
} from "@formily/antd";
import { Button, Spin } from "antd";
//@ts-ignore
import Modal from "@/components/Modal/Modal";
import { IReaction } from "@designable/formily-setters/lib/components/ReactionsSetter/types";
import "./styles.less";
import { ColorInput } from "@designable/react-settings-form";
interface IStaticsSetterProps {
  value?: IReaction & { statics: any[]; staticsFixed: boolean };
  onChange?: (value: IReaction) => void;
}

const SchemaField = createSchemaField({
  components: {
    Input,
    Select,
    FormItem,
    ArrayTable,
    NumberPicker,
    Switch,
  },
});

export const StaticsSetter: React.FC<IStaticsSetterProps> = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [innerVisible, setInnerVisible] = useState(false);
  const prefix = usePrefix("table-setter");

  const form = useMemo(() => {
    return createForm({
      values: clone(props.value),
    });
  }, [modalVisible, props.value]);

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  useEffect(() => {
    if (modalVisible) {
      requestIdle(
        () => {
          setInnerVisible(true);
        },
        {
          timeout: 4000,
        }
      );
    } else {
      setInnerVisible(false);
    }
  }, [modalVisible]);
  return (
    <>
      <Button block onClick={openModal}>
        配置统计
      </Button>
      <Modal
        title="配置统计"
        width={'80vw'}
        style={{ top: 20 }}
        bodyStyle={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          maxHeight: "80vh",
          minHeight: 320,
          background: "#fff",
          padding: "16px 24px"
        }}
        centered
        transitionName=""
        maskTransitionName=""
        open={modalVisible}
        onCancel={closeModal}
        destroyOnClose
        onOk={async () => {
          form.validate().then(() => {
            form.submit((values) => {
              props.onChange?.(values);
            });
            closeModal();
          });
        }}
      >
        <div className={prefix}>
          {innerVisible ? (
            <Form form={form}>
              <SchemaField>
                <SchemaField.Boolean
                  title="是否固定"
                  name="staticsFixed"
                  x-decorator="FormItem"
                  x-component="Switch"
                />
                <SchemaField.Array
                  name="statics"
                  default={[]}
                  x-component="ArrayTable"
                  x-component-props={{
                    pagination: false,
                    className: "asdfg",
                    scroll: { x: "100%", y: "calc(80vh - 335px)" },
                    onAdd: (index) => {
                      const cols = form.getValuesIn("statics");
                      if (!cols[cols.length - 1].dataIndex) {
                        cols[cols.length - 1].dataIndex = uid();
                      }
                      form.setValuesIn("statics", cols);
                    },
                  }}
                >
                  <SchemaField.Object>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      name="column1"
                      x-component-props={{
                        width: 50,
                        title: "排序",
                        align: "center",
                      }}
                    >
                      <SchemaField.Void
                        x-decorator="FormItem"
                        required
                        x-component="ArrayTable.SortHandle"
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: "数据",
                        width: 120,
                      }}
                    >
                      <SchemaField.String
                        name="title"
                        x-decorator="FormItem"
                        x-component="Input"
                        required
                        x-component-props={{
                          placeholder: "请输入",
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: GlobalRegistry.getDesignerMessage(
                          "customSettingComponents.TableSetter.dataIndex"
                        ),
                        width: 120,
                      }}
                    >
                      <SchemaField.String
                        name="dataIndex"
                        x-decorator="FormItem"
                        required
                        x-component="Input"
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: "跨列",
                        width: 100,
                      }}
                    >
                      <SchemaField.String
                        name="colSpan"
                        x-decorator="FormItem"
                        x-component="NumberPicker"
                        x-component-props={{
                          placeholder: "请输入",
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: "颜色",
                        width: 100,
                      }}
                    >
                      <SchemaField.String
                        name="color"
                        x-decorator="FormItem"
                        x-component={ColorInput}
                        x-component-props={{
                          placeholder: "请输入",
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: "对齐方式",
                        width: 100,
                      }}
                    >
                      <SchemaField.String
                        name="align"
                        x-decorator="FormItem"
                        x-component="Select"
                        enum={[
                          { label: "左对齐", value: "left" },
                          { label: "居中", value: "center" },
                          { label: "右对齐", value: "right" },
                        ]}
                        x-component-props={{
                          placeholder: "请选择",
                          defaultValue: "left",
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: GlobalRegistry.getDesignerMessage(
                          "SettingComponents.ReactionsSetter.operations"
                        ),
                        align: "center",
                        width: 80,
                      }}
                    >
                      <SchemaField.Markup
                        type="void"
                        x-component="ArrayTable.Remove"
                      />
                    </SchemaField.Void>
                  </SchemaField.Object>
                  <SchemaField.Void
                    title={GlobalRegistry.getDesignerMessage(
                      "SettingComponents.ReactionsSetter.addRelationField"
                    )}
                    x-component="ArrayTable.Addition"
                    x-component-props={{ style: { marginTop: 8 } }}
                  />
                </SchemaField.Array>
              </SchemaField>
            </Form>
          ) : (
            <div className="example">
              <Spin />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
