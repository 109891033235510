import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import ProTable, { ProTableProps } from '@ant-design/pro-table'
import { RowSelectionType } from 'antd/lib/table/interface';
import { useForm } from '@formily/react'
import { getOptions, clickEventToProps } from '../../../service';
import { uid } from '@designable/shared';
import {  Table, Space, Button, Popconfirm, Form, Input, Checkbox, Image, Switch, Tag, Select } from 'antd'
import { useField } from '@formily/react';
import { EditableCell, EditableRow } from './editableCell'
import { Icon } from "../../setters";
import './styles.less'

const transToMap = (arr = []) => {
  return arr.reduce((accumulator, current) => {
    accumulator[current.value] = {
      text: current.text,
      status: current.color
    };
    return accumulator;
  }, {});
}

const fillColor = (rowArr = [], arr = [], index) => {
  let colorObj = null;
  for (let obj of rowArr) {
    if (obj.value.includes(index)) {
      colorObj = obj;
    }
  }
  for (let obj of arr) {
    if (obj.value.includes(index)) {
      colorObj = obj;
    }
  }
  return colorObj;
}


const customType = ['button', 'input', 'switch', 'wrapText', 'checkbox', 'labels', 'dropSelect']
const enumTypeList = ['select']

export interface FormilyProTableProps extends ProTableProps<any, any, any> {
  tableProps?: any // 废弃
  showPagination: boolean
  pageSize: string | number
  scrollY?: string
  showSelection?: boolean
  selectionType?: RowSelectionType
  staticsFixed?: boolean
  statics?: any
  actions?: any[]
  columns: any[]
  rowBgColor?: any[]
  staticsProps?: any
  dataSource: any[]
}

export const PreviewProTable: ReactFC<FormilyProTableProps> = ({ columns=[], actions = [], dataSource, staticsProps = {}, rowBgColor=[], tableProps, showPagination, scrollY, showSelection, selectionType, style, ...props}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const tableRef = useRef();
  const { staticsFixed = false, statics } = staticsProps;
  const form = useForm()
  const field = useField()
  const [tableDataSource, setTableDataSource] = useState<any[]>(dataSource || [])
  useEffect(() => {
    field.setData(selectedRowKeys);
  }, [selectedRowKeys])
  useEffect(()=> {
    setTableDataSource(dataSource)
    return () => {
      tableRef?.current?.reload();
    }
  }, [dataSource])
  const handleSave = (row) => {
    const newData = [...tableDataSource];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setTableDataSource(newData);
    tableRef?.current?.reload();
  };
  const handleInputSave = (e, row) => {
    const val = e.target.value;
  }
  const formatCols = columns.filter(item => !!item.dataIndex).map(item => {
    const col: any = {
      title: item.title,
      dataIndex: item.dataIndex,
      align: item.align,
      width: item.width,
      fixed: item.fixed,
      copyable: item.copyable,
      ellipsis: item.ellipsis,
      // onFilter: item.onFilter,
      filters: item.onFilter ? (item.enumsProps || [{ text: '全部', value: '' }]) : false,
      tooltip: item.tooltip,
      render: (record, _, index) => {
        let recordNode: ReactNode = record;
        if (item.textType == 'button') {
          const clickEventProps = item.clickEventProps || {};
          if (props['data-designer-node-id'] || field.decoratorProps['data-designer-node-id']) {
            return <Button key={item.actionId} type="link">{record}</Button>
          }
          const recordClickProps = _[`${item.dataIndex}props`];
          let clickProps: any = {};
          let btnProps:any = {
            key: item.actionId,
            type: 'link',
          }
          if(clickEventProps) {
            clickProps = recordClickProps ? clickEventToProps(recordClickProps, form) : clickEventToProps(clickEventProps, form);
          }
          recordNode = <Button {...btnProps} onClick={()=> {clickProps.onClick && clickProps.onClick();}} >{record}</Button>
        }
        if (item.textType == 'input') {
          recordNode = <Input style={{ width: '100%'}} placeholder='请输入' defaultValue={record} onPressEnter={(e) => handleInputSave(e, _)} onBlur={(e) => handleInputSave(e, _)}/>
        }
        if (item.textType == 'dropSelect') {
          recordNode = <Select style={{ width: '100%'}} defaultValue={record == '-' ? undefined : record} allowClear placeholder='请选择' options={item.optionsProps}></Select>
        }
        if (item.textType == 'wrapText') {
          recordNode = <pre>{record}</pre>
        }
        // if (item.textType == 'image') {
        //   return <Image src={record} style={{ maxWidth: '60px', maxHeight: '60px' }} preview={false}/>
        // }
        if (item.textType == 'switch') {
          recordNode = <Switch defaultChecked={record == 1} disabled={true}/>
        }
        if (item.textType == 'checkbox') {
          recordNode = <Checkbox defaultChecked={record == 1} disabled={true}/>
        }
        if (item.textType == 'labels') {
          const { children } = record?.props || {};
          let text = children ? children : record
          //@ts-ignore
          const arr = `${text}`.replaceAll('，', ',').split(',');
          const valueEnum = transToMap(item.enumsProps)
          recordNode = (<Space>
            {
              arr.map((jtem, jndex) => {
                const statusInfo = valueEnum[jtem];
                if (statusInfo) {
                  return (
                    <Tag key={jndex} color={statusInfo.status}>
                      {statusInfo.text}
                    </Tag>
                  );
                }
                return jtem
              })
            }
          </Space>)
        }
        if (item.ellipsis) {
          recordNode = <div style={{ width: item.width }}>{recordNode}</div>
        }

        return recordNode
      }
    };
    if (item.bgColorList && item.bgColorList.length > 0 || rowBgColor.length > 0) {
      col.onCell = (record, index) => {
        const colorObj = fillColor(rowBgColor, item.bgColorList, index + 1) || {};
        const bgColor = colorObj.color; // 假设fillColor函数返回颜色
        const textColor = colorObj.textColor
        return {
          style: {
            backgroundColor: bgColor || 'initial', // 如果没有颜色返回，则使用默认值
            color: textColor
          },
        };
      }
    };
    if (!customType.includes(item.textType)) {
      col.valueType = item.textType
    }
    if (enumTypeList.includes(item.textType)) {
      col.valueEnum = transToMap(item.enumsProps)
    }
    if (item.textType == 'radio') {
      col.valueEnum = {
        1: {
          text: '未解决',
          status: 'Error',
        },
        2: {
          text: '已解决',
          status: 'Success',
        },
      }
    }
    if (item.textType != 'editText' || props['data-designer-node-id'] || field.decoratorProps['data-designer-node-id']) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: item.textType == 'editText',
        dataIndex: item.dataIndex,
        title: col.title,
        handleSave,
      }),
    };

  })
  // 处理actions数据
  const operations = actions.map(item => {
    return{
      actionTitle: '按钮',
      ...item,
      actionId: uid()
    }
  })
  const newCols = operations && operations.length > 0 ? [
    ...formatCols,
    {
      title: '操作',
      key: 'action',
      align: operations[0].align,
      width: operations[0].width,
      fixed: operations[0].fixed,
      render: (_, record) => (
        <>
          {
            operations.map(item => {
              const clickEventProps = item.clickEventProps || {};
              if (props['data-designer-node-id'] || field.decoratorProps['data-designer-node-id']) {
                return <Button icon={item.iconName ? <Icon name={item.iconName} /> : null} key={item.actionId} style={{ color: item.textColor}} type="link">{item.actionTitle}</Button>
              }
              const actionType = clickEventProps.actionType || clickEventProps.defaultAction?.actionType;
              let btnProps:any = {
                key: item.actionId,
                type: 'link',
              }
              let clickProps: any = {};
              if (item.iconName) {
                btnProps.icon = <Icon name={item.iconName} />
              }
              // if (actionType.includes("link")) {
              //   btnProps.href = href;
              //   btnProps.target = targetType;
              // }
              if (actionType == 'del') {
                return (
                  <Popconfirm
                    title="确认删除?"
                    onConfirm={() => {}}
                    onCancel={()=> {}}
                    okText="确认"
                    cancelText="取消"
                    key={item.actionId}
                  >
                    <a>{item.actionTitle}</a>
                  </Popconfirm>

                )
              }
              if(clickEventProps) {
                // clickProps = clickEventToProps(clickEventProps);
                clickProps = clickEventToProps(clickEventProps, form);
              }
              const handleCLick = () => {
                let props = null;
                if(item.withProps) {
                  props = record
                }
                clickProps.onClick(props)
              }

              return <Button {...btnProps} key={item.actionId} style={{ color: item.textColor}}  onClick={handleCLick} type="link">{item.actionTitle}</Button>
            })
          }
        </>
      ),
    }
  ] : formatCols;
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  if (showSelection) {
    props.rowSelection = {
      type: selectionType || 'checkbox',
      selectedRowKeys,
      onChange: onSelectChange,
      fixed: 'left',
      alwaysShowAlert: false
    }
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  }

  return (<div className="dn-ProTable" style={style}>
    <ProTable
      search={false}
      {...props}
      components={components}
      actionRef={tableRef}
      options={false}
      tableAlertRender={() => false}
      tableAlertOptionRender={() => false}
      columns={newCols}
      request={(params) => {
        return new Promise((resolve) => {
          resolve({
            data: tableDataSource,
            success: true,
            total: tableDataSource.length,
          });
        });
      }}
      summary={() => {
        if (!statics || statics.length == 0) {
          return null;
        }
        return (
          <Table.Summary fixed={staticsFixed}>
            <Table.Summary.Row>
              {statics.map((item, index) => {
                return (
                  <Table.Summary.Cell
                    key={item.dataIndex}
                    colSpan={item.colSpan}
                    index={index}
                  >
                    <div style={{ color: item.color, textAlign: item.align }}>
                      {item.title}
                    </div>
                  </Table.Summary.Cell>
                );
              })}
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
      scroll={{
        x: "max-content",
        y: scrollY ? scrollY : undefined,
      }}
      rowKey={(record: any) => {
        return record.id
      }}
      pagination={
        showPagination
          ? {
              defaultPageSize: 10,
              pageSizeOptions: [10, 20, 30, 40],
              showQuickJumper: true,
              showSizeChanger: true,
            }
          : false
      }
      rowClassName={() => "editable-row"}
    />
  </div>);
}

const FormilyProTable: ReactFC<FormilyProTableProps> = connect(
  PreviewProTable,
)

export default FormilyProTable
