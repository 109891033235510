import React, { useEffect, useMemo, useState } from 'react'
import { createForm, createEffectHook } from '@formily/core'
import { FormProvider, createSchemaField } from '@formily/react'
import {
  FormLayout,
} from '@formily/antd';
import {  Modal, Button as AntdButton, Spin, Form, Input } from 'antd'
import { css } from '@emotion/react'
//@ts-ignore
import openWindow from '@/script/openWindow.mjs';
import { SchemaField } from './PreviewWidget'
import { useSchemaService } from '../service';


const cssLoadingBox = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  minHeight: '300px'

})

const onOpenModalEvent = createEffectHook(
  "open-modal",
  (payload, form) => (listener) => {
    listener(payload, form);
  }
);

const onCtrlClick = createEffectHook(
  "ctrl-click",
  (payload, form) => (listener) => {
    listener(payload, form);
  }
);

const onHideModal = createEffectHook(
  "hide-modal",
  (payload, form) => (listener) => {
    listener(payload, form);
  }
);

const onUpdateNavigate = createEffectHook('update-navigate', 
  (payload, form) => (listener) => {
    listener(payload, form)
  }
)

export default function DynamicModal({value, onOk, form, modalId, modalDetail, otherProps, handlNavigate, ...props}: any) {
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalSchema, setModalSchema] = useState<any>({});
    const schemaService = useSchemaService();

    useEffect(() => {
      if(modalDetail.modalType == "customPage") {
        getData()
      }
    }, [])
    const getData = async() => {
      setModalLoading(true)
      const data = await schemaService.loadSchema(undefined, modalId)
      setModalLoading(false)
      setModalSchema(JSON.parse(data?.uvMap || '') || {});
    }
    const form2 = useMemo(() => createForm({
      values: otherProps || {},
      effects() {
        onCtrlClick((payload, $form) => {
            form.notify('ctrl-click', payload)
        }),
        onHideModal(() => {
            onOk()
        }),
        onOpenModalEvent((payload, $form) => {
            form.notify('open-modal', payload)
        }),
        onUpdateNavigate((payload) => {
          handlNavigate(payload)
        })
      }
    }), [])
   
    const handleCancel = () => {
      onOk();
    };
  
    const handleOk = () => {
      form2.validate().then(() => {
        form2.submit(vals => {
          setLoading(true);
          if (modalDetail.okBtnProps?.okCallbackPage?.pageId) {
            const targetType =  modalDetail.okBtnProps?.okTarget ? '_blank' : '_self';
            setTimeout(() => {
              setLoading(false);
              const params = modalDetail.okBtnProps?.widthModalProps ? vals : {};
              openWindow('/formilyPreview', { ...params, uvId: modalDetail.okBtnProps?.okCallbackPage?.pageId }, targetType)
              onOk()
            }, 500);
          } else {
            setLoading(false);
            onOk(false);
          }
        })
      })
    };
    const footerEl: any[] = [modalDetail?.cancelBtnProps?.show === undefined ? true : modalDetail?.cancelBtnProps?.show , modalDetail?.okBtnProps?.show === undefined ? true : modalDetail?.okBtnProps?.show];
  
  
    return (
      <FormProvider form={form2}>
        <Modal
          width={
            modalDetail.modalType == "customPage"
              ? modalSchema.form?.style?.width || "60vw"
              : "420px"
          }
          title={
            modalDetail.modalType != "customPage" ? modalDetail.title : null
          }
          onCancel={handleCancel}
          bodyStyle={{
            overflow: "auto",
            padding: "0px",
          }}
          destroyOnClose={true}
          footer={
            modalDetail.modalType == "customPage"
              ? null
              : footerEl.map((item, index) => {
                  if (item == "1" && index == 0) {
                    return (
                      <AntdButton key="back" onClick={handleCancel}>
                        {modalDetail?.cancelBtnProps?.text || "取消"}
                      </AntdButton>
                    );
                  }
                  if (item == "1" && index == 1) {
                    return (
                      <AntdButton
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleOk}
                      >
                        {modalDetail?.okBtnProps?.text || "确认"}
                      </AntdButton>
                    );
                  }
                })
          }
          {...props}
        >
          {modalDetail.modalType == "customPage" && (
            //@ts-ignore
            modalLoading ? (<div css={{...cssLoadingBox}}> <Spin /> </div>) : 
            <FormLayout labelCol={6} wrapperCol={12}>
              <div
                
                style={{ ...modalSchema?.form?.style, width: "100%" }}
              >
                <SchemaField schema={modalSchema.schema}></SchemaField>
              </div>
            </FormLayout>
          )}
          {
            modalDetail.modalType == "inputModal" &&
            <div style={{ padding: "12px 24px" }}>
              <Form
                layout='vertical'
              >
                <Form.Item
                  label={modalDetail.labelName}
                  name="username"
                >
                  <Input.TextArea placeholder='请输入...' />
                </Form.Item>
              </Form>
            </div>
          }
          {
            (!modalDetail.modalType || modalDetail.modalType == "confirmModal") &&
            <div style={{ padding: "12px 24px" }}>
              {modalDetail.modalContent}
            </div>
            
          }
        </Modal>
      </FormProvider>
    );
  };